export const work = {
  'TAFS AT WORK': 'TAFS工作中',
  'TAFSAtWorkP': 'TAFS利用强大而全面的交易行为模式库来帮助企业监控活动并发现可能导致不合规监管和市场声誉损失的行为。覆盖范围包括（且不限于以下事例）：',

  'ex-h1': '市场操纵与滥用',
  'ex-p1': '标记开/关，欺骗和分层，清洗交易和停止，欺骗等',
  'ex-h2': '内幕交易和控制室',
  'ex-p2': '在重大事件、正面运行相关产品之前进行交易，对受限制的列表进行交易',
  'ex-h3': '市场外定价和最佳执行',
  'ex-p3': '市场外合理定价，过度的加价/降价，价格变坏，订单执行不及时',
  'ex-h4': '市场价格中的高级欺诈计划',
  'ex-p4': '贸易商和客户使用雇主/客户资金用于洗钱或个人牟利的复杂计划 ',
  'ex-h5': '简单的市场外定价',
  'ex-p5': '简单的市场外交易',
  'ex-h6': '复杂的市场外定价',
  'ex-p6': '在给定的历史日期（OTC期权，SWAP，REPO，IRS，CIRS）无精确市场价格的复杂的市场外交易',
  'ex-h7': '将市场外交易视为市场交易',
  'ex-p7': '低流动性市场中个别交易者的价格操纵，旨在使市​​场外交易看起来像市场交易',
  'ex-h8': '仓位停止',
  'ex-p8': '季终/年终资产负债表窗口修整活动以隐藏损失。检测到亏损仓位时停止以保护交易者的获利。盈利仓位停止以回避仓位限制并增加市场曝光率',
  'ex-h9': '非法跨境资本交易',
  'ex-p9': '通过金融市场引导的免费跨境资金',
  'ex-h10': '清洗交易',
  'ex-p10': '旨在通过闭环股票交易和OTC配对交易将客户资金非法转移到其他银行或特殊目的工具的交易',

  'UNUSUAL TRADING ACTIVITY': '异常交易活动',
  'Unusual volume traded': '异常交易量',
  'Unusual communications': '异常沟通',
  'Unusual traded products': '异常交易产品',
  'Unusual counterparts': '异常同行',
  'Unusual clients': '异常客户',
  'Unusual jurisdictions': '异常司法管辖区',
  'Register now': '现在注册',
}

export default work
