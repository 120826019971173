export const features = {
  'FEATURES': '功能',

  'TAFS KEY DIFFERENTIATORS': 'TAFS关键微分器',
  'diff1': '可视化工具加速对欺诈的检测',
  'diff2': '可调节的算法参数以减少误报警报',
  'diff3': '行业专家意见和联系人不断更新欺诈计划及其防范方法',
  'diff4': '大量成熟而强大的算法基础，以减少漏报',
  'diff5': '预先安装的算法基础缩短实施时间',
  'diff6': '关于交易者逻辑的第一手知识可确保对任何欺诈偏差的检测和算法化',

  'KEY FEATURES': '主要特点',
  'KeyFeaturesP': '借助先进的数据挖掘工具和算法检测复杂的行为模式是TAFS的标志性技术。此外，TAFS还提供以下关键功能',
  'key-h1': '灵活性和可配置性:',
  'key-p1': '在整个应用程序中，从用户界面到底层警报生成方法，用户可以轻松定制应用程序以满足公司或个人用户的任何特定需求。',
  'key-h2': '报告、分析和仪表板：',
  'key-p2': 'TAFS提供从简单到复杂的各种模式识别方法。TAFS用整套强大的分析和报告工具辅助模式检测。',
  'key-h3': '加速实施流程：',
  'key-p3': '质量数据始终是质量警报的驱动。TAFS提供多种数据采集方法，可以比以往更快地启动和运行。',
  'key-h4': '扩展算法库：',
  'key-p4': '借助100多种用于检测异常交易活动的独特算法，TAFS可满足每个全球市场中各种规模的金融机构的要求。',
  'key-h5': '高度可配置的算法：',
  'key-p5': '合规性不是一个一刀切的业务。TAFS预构建算法可轻松配置参数，使机构能够将其各自的合规性理念、风险承受能力和人力资源可用性与适当的监控和分析水平相匹配。',
  'key-h6': '阈值设定器：',
  'key-p6': '阈值设定器查看历史警报以帮助公司磨合理想设置，并定量向监管机构展示公司决策的基本原理。',
  'key-h7': '自动关联查找器：',
  'key-p7': '该工具自动搜索所有TAFS警报以识别可能未被发现的关系。',
  'key-h8': '调查中心：',
  'key-p8': 'TAFS提供与警报工作流程集成的独立案例管理工作流程。用户可以生成新案例或将现有警报推广到案例，从而实现更全面和适合企业的合规风险管理方法。',
  'key-h9': '扩展警报视角：',
  'key-p9': '核心算法从多个角度生成警报，对个人交易者、交易台/长凳/账簿、客户账户、其他公司员工、个人证券、订单和/或执行发出警报。',
  'key-h10': '高级数据模型：',
  'key-p10': '丰富的数据集可实现质量警报并有效分析这些警报。TAFS利用强大的数据模型进行合规风险管理，这对于识别对金融公司构成声誉和监管风险威胁的复杂行为是至关重要的。',
  'key-h11': '全方位手段覆盖：',
  'key-p11': 'TAFS可以轻松适应更复杂的资产类别。当与现有股票、固定收益和商品算法库相结合时，通过性能设计的阈值设置和根据不同资产类别需求调整的已更新的表示层，公司可以将算法与其他资产类别重新定向。',
}

export default features
