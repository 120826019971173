import React from 'react';

import news1_1 from '../../../assets/img/news/news1_1.jpeg';
import news1_2 from '../../../assets/img/news/news1_2.jpeg';

const News1 = () => {
  return (
    <div className="news-container">
      <p>
        <div className="news-date">November 15th</div>
        RegTech Summit New York City On November 15th, AutreTech took part in
        RegTech Summit, which was organized in New York, USA. The RegTech Summit
        brings together the community of practitioners managing regulatory
        change and implementing RegTech solutions.
        <br />
        The topics discussed at the Summit include Regulatory Reporting,
        Managing Regulatory Change, FinTech, Artificial Intelligence &amp;
        Machine Learning, Blockchain, Financial Crime. Gennady Belov (Director
        of R&amp;D), Nicholas Decker (Director of Operations) and Ms. Zheng Song
        (Director of Business Development) personally represented AutreTech at
        the Summit and demonstrated the capabilities of TAFS-engine software to
        more than 100 of Wall-Street financial markets participants. AutreTech
        is proud to have taken part in the New York RegTech Summit.
      </p>

      <div className="news-images-block">
        <img className="news-image" src={news1_1} alt="img" />
        <img className="news-image" src={news1_2} alt="img" />
      </div>
    </div>
  );
};

export default News1;
