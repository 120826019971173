export const features = {
  'FEATURES': 'FEATURES',

  'TAFS KEY DIFFERENTIATORS': 'TAFS KEY DIFFERENTIATORS',
  'diff1': 'Vizualization Tools to speed up detection of frauds',
  'diff2': 'Adjustable algorithm parameters to reduce false positive alerting',
  'diff3': 'Industry expertise and contacts provide continuous updates on fraud schemes and ways of their prevention',
  'diff4': 'Massive base of mature and robust algorithms to reduce false negatives',
  'diff5': 'Pre-installed base of algorithms shorten implementation timeframe',
  'diff6': 'First hand knowledge of trader’s logic ensures detection and algorithmization of any deviations towards fraud',

  'KEY FEATURES': 'KEY FEATURES',
  'KeyFeaturesP': 'Detection of complex behavioral patterns by sophisticated data mining tools and algorithms is the hallmark of the TAFS. In addition TAFS offers the following key capabilities:',
  'key-h1': 'Flexibility and Configurability:',
  'key-p1': 'Throughout the application, from the user interface to the underlying alert generation approach, users can readily tailor the application to meet any specific needs of the company or an individual user.',
  'key-h2': 'Reporting, Analytics, and Dashboards:',
  'key-p2': 'TAFS offers a range of pattern identification approaches ranging from simple to complex. TAFS complements pattern detection with a robust set of tools for analytics and reporting.',
  'key-h3': 'Expedited Implementation Processes:',
  'key-p3': 'Quality data will always be the driver of quality alerts. TAFS provides multiple approaches to data acquisition that can get you up and running faster than ever.',
  'key-h4': 'Extended Algorithm Library:',
  'key-p4': 'With over 100 unique algorithms for detecting unusual trading activity, TAFS meets requirements of financial institutions of all sizes in every global market.',
  'key-h5': 'Highly Configurable Algorithms:',
  'key-p5': 'Compliance is not a one-size-fits-all business. TAFS pre-built algorithms are easily configured with parameters that allow institutions to match their individual compliance philosophy, risk tolerance, and personnel resource availability with the appropriate level of monitoring and analysis.',
  'key-h6': 'Threshold Setter:',
  'key-p6': 'Threshold Setter looks at historic alerts to help companies hone in on the ideal settings and demonstrate quantitatively to regulators the rationale for the company’s decisions.',
  'key-h7': 'Automated Correlation Finder:',
  'key-p7': 'This tool automatically searches across all TAFS alerts to identify potentially undiscovered relationships.',
  'key-h8': 'Investigation Center:',
  'key-p8': 'TAFS offers a separate case management workflow integrated with the alert workflow. Users can generate new cases or promote existing alerts to cases enabling a more holistic and enterprise approach to compliance risk management.',
  'key-h9': 'Expanded Alert Perspectives:',
  'key-p9': 'Core algorithms generate alerts from multiple perspectives enabling alerts on individual traders, trading desks/benches/books, customer accounts, other company employees, individual securities, orders, and/or executions.',
  'key-h10': 'Advanced Data Model:',
  'key-p10': 'Rich data set enables quality alerts and the efficient analysis of those alerts. TAFS leverages a robust data model for compliance risk management that is essential for identifying the complex behaviors that most threaten the financial company in the form of reputational and regulatory risk.',
  'key-h11': 'Comprehensive Instrument Coverage:',
  'key-p11': 'TAFS can easily adapt to more complex asset classes. When combined with existing library of algorithms for Equities, Fixed Income, and Commodities, companies can re-direct algorithms against other asset classes through performance-engineered threshold settings and updated presentation layers adjusted to the demands of different asset classes.',
}

export default features
