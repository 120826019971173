import header from './header';
import navmenu from './navmenu';
import pages from './pages/index';
import footer from './footer';

export const dictionaryCn = {
  translations: {
    ...header,
    ...navmenu,
    ...pages,
    ...footer
  }
}

export default dictionaryCn
