import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import logo from '../assets/img/brand/logo-light.png';
import menu from './menu';

class Footer extends Component {
  render() {
    return (
      <div id='autre-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <Navbar className='p-0'>
                <NavbarBrand href='/'><img src={logo} alt='autretech' height={'50px'} /></NavbarBrand>
              </Navbar>
              {/*<div className='p-2'><Trans>FooterMoto</Trans></div>*/}
            </div>
            <div className='col-md-7'>
              <Navbar className='pt-3'>
                <Nav className='ml-sm-auto'>
                  {
                    menu().map((item, index) => {
                      if (index > 0) return (
                        <NavItem key={item.id}>
                          <NavLink to={item.ref} className='footer-item'><Trans>{item.name}</Trans></NavLink>
                        </NavItem>
                      ); else return null
                    })
                  }
                </Nav>
              </Navbar>
              {/*<Navbar className='p-2'>
                <Nav className='ml-sm-auto'>
                  <NavItem>
                    <NavLink to='#' className='footer-icon'><FontAwesome name='twitter' /></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to='#' className='footer-icon'><FontAwesome name='facebook' /></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to='#' className='footer-icon'><FontAwesome name='google-plus' /></NavLink>
                  </NavItem>
                </Nav>
              </Navbar>*/}
            </div>
          </div>
          <div id='copyright-text'>
            <FontAwesome name='copyright'/>{this.getYear()} TAFS Intelligence. <Trans>All rights reserved</Trans>.
          </div>
        </div>
      </div>
    );
  }

  getYear() {
    var d = new Date();
    return d.getFullYear();
  }
}

export default Footer
