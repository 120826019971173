import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Button } from 'reactstrap';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import VideoContainer from './home/VideoContainer';
import RegistrationForm from './home/RegistrationForm';
// import TeamCarousel from './home/TeamCarousel';
import withLogin from '../decorators/withLogin';

class Home extends Component {
  static propTypes = {
    // from withLogin
    loggedIn: PropTypes.bool.isRequired,
  }

  render() {
    configureAnchors({offset: -60});
    return (
      <div>
        <div id='home-image'>
          {
            this.props.loggedIn ? (
              <div className='container text-white text-center'>
                <div>
                  <h1 id='home-header'>TAFS INTELLIGENCE</h1>
                  <p><Trans>An international team of professionals in financial markets with R&D and management teams located in USA, Europe and Asia</Trans></p>
                </div>
                <div className='mb-5'>
                  <VideoContainer />
                </div>
                <Button id='register-button' className='btn-danger autre-red-button my-1 mr-3 py-2 px-5' href='#register'><Trans>Register</Trans></Button>
                <Button id='learnmore-button' className='btn-light autre-transparent-button my-1 py-2 px-5' href='#more'><Trans>Learn More</Trans></Button>
              </div>
            ) : (
              <div className='container text-white text-center'>
                <div>
                  <h1 id='home-header'>TAFS INTELLIGENCE</h1>
                  <p><Trans>An international team of professionals in financial markets with R&D and management teams located in USA, Europe and Asia</Trans></p>
                </div>
                <div className='mx-auto text-center'>
                  <Button id='register-button' className='btn-danger autre-red-button my-1 mr-3 py-2 px-5' href='#register'><Trans>Register</Trans></Button>
                  <Button id='learnmore-button' className='btn-light autre-transparent-button my-1 py-2 px-5' href='#more'><Trans>Learn More</Trans></Button>
                </div>
              </div>
            )
          }
        </div>
        <ScrollableAnchor id='more'>
          <div className='container text-autre-blue my-5'>
            <h2 className='text-sm-center font-weight-bold'><Trans>Protection from</Trans></h2>
            <p className='text-sm-center opacity-7 px-sm-5 mx-sm-5 mb-5'><Trans>ProtectionP</Trans></p>
            <div className='row my-5'>
              <div className='col-sm-6 col-lg-3 left-border-autre-blue mb-3'>
                <h5 className='font-weight-bold'><Trans>FRAUD</Trans></h5>
                <p className='opacity-7'><Trans>in financial markets</Trans></p>
              </div>
              <div className='col-sm-6 col-lg-3 left-border-autre-blue mb-3'>
                <h5 className='font-weight-bold'><Trans>ROGUE TRADING</Trans></h5>
                <p className='opacity-7'><Trans>hidden losses/ hidden positions/ hidden market & credit risks</Trans></p>
              </div>
              <div className='col-sm-6 col-lg-3 left-border-autre-blue mb-3'>
                <h5 className='font-weight-bold'><Trans>MONEY LAUNDERING</Trans></h5>
                <p className='opacity-7'><Trans>in financial markets</Trans></p>
              </div>
              <div className='col-sm-6 col-lg-3 left-border-autre-blue mb-3'>
                <h5 className='font-weight-bold'><Trans>MARKET ABUSE</Trans></h5>
                <p className='opacity-7'><Trans>insider trading, market manipulation</Trans></p>
              </div>
            </div>
            <p className='font-italic text-center'><Trans>More1</Trans></p>
            <p className='bg-autre-blue text-white p-3 my-3'><Trans>More2</Trans></p>
          </div>
        </ScrollableAnchor>
        <ScrollableAnchor id='register'>
          <div id='home-register' className='my-5'>
            <div className='container py-5'>
              <RegistrationForm />
            </div>
          </div>
        </ScrollableAnchor>
        <div className='container'>
          {/* <TeamCarousel /> */}
        </div>
      </div>
    )
  }
}

export default withLogin(Home)
