import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import icon1 from '../../assets/img/icons/icon1.svg';
import icon2 from '../../assets/img/icons/icon2.svg';
import icon3 from '../../assets/img/icons/icon3.svg';
import icon4 from '../../assets/img/icons/icon4.svg';
import icon5 from '../../assets/img/icons/icon5.svg';
import icon6 from '../../assets/img/icons/icon6.svg';
import check from '../../assets/img/icons/check.svg';
import mac from '../../assets/img/features/macs.svg';

class Features extends Component {
  render() {
    return (
      <div>
        <div id='features-image'>
          <div className='container text-white text-center'>
            <h1 id='features-header'><Trans>FEATURES</Trans></h1>
          </div>
        </div>
        <div className='container my-5'>
          <h2 className='text-autre-blue font-weight-bold text-center'><Trans>TAFS KEY DIFFERENTIATORS</Trans></h2>
          <div className='row mt-5'>
            {
              features.map((feature, index) => {
                return (
                  <div key={index} className='col-md-6'>
                    <div className='row my-5'>
                      <div className='col-lg-2 col-sm-3 text-center mt-3'>
                        <img src={feature.icon} alt=''/>
                      </div>
                      <div className='col-lg-10 col-sm-9 font-weight-bold text-uppercase text-center text-sm-left mt-3'>
                        <Trans>{feature.text}</Trans>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div id='features-mac'>
          <div className='container my-5'>
            <h2 className='text-autre-blue font-weight-bold text-center'><Trans>KEY FEATURES</Trans></h2>
            <p className='text-center'><Trans>KeyFeaturesP</Trans></p>
            <div className='row text-autre-blue mt-5'>
              <div className='d-none d-md-block d-xl-none col-md-6 order-md-1 my-3 pt-3 pt-lg-5 text-center' style={{height: '1px'}}>
                <img src={mac} alt='' />
              </div>
              <div className='d-none d-md-block d-xl-none col-md-6 order-md-2 my-3'>
              </div>
              <div className='d-none d-lg-block d-xl-none col-md-6 order-md-3 my-3'>
              </div>
              {
                keys.map((key, index) => {
                  return (
                    <div key={'key'+index} className={'col-xl-5 col-md-6 my-3 order-md-' + index}>
                      <div className='row'>
                        <div className='col-2'>
                          <img src={check} alt=''/>
                        </div>
                        <div className='col-10'>
                          <h5 className='font-weight-bold'><Trans>{key.head}</Trans></h5>
                          <Trans>{key.text}</Trans>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const features = [
  {
    icon: icon1,
    text: 'diff1',
  },
  {
    icon: icon2,
    text: 'diff2',
  },
  {
    icon: icon3,
    text: 'diff3',
  },
  {
    icon: icon4,
    text: 'diff4',
  },
  {
    icon: icon5,
    text: 'diff5',
  },
  {
    icon: icon6,
    text: 'diff6',
  },
]

const keys = [
  {
    head: 'key-h1',
    text: 'key-p1'
  },
  {
    head: 'key-h2',
    text: 'key-p2'
  },
  {
    head: 'key-h3',
    text: 'key-p3'
  },
  {
    head: 'key-h4',
    text: 'key-p4'
  },
  {
    head: 'key-h5',
    text: 'key-p5'
  },
  {
    head: 'key-h6',
    text: 'key-p6'
  },
  {
    head: 'key-h7',
    text: 'key-p7'
  },
  {
    head: 'key-h8',
    text: 'key-p8'
  },
  {
    head: 'key-h9',
    text: 'key-p9'
  },
  {
    head: 'key-h10',
    text: 'key-p10'
  },
  {
    head: 'key-h11',
    text: 'key-p11'
  }
]

export default Features
