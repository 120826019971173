export const header = {
  'SignIn': 'Sign in',
  'SignOut': 'Sign out',
  'username': 'username',
  'password': 'password',
  'SIGN IN FAILED': 'SIGN IN FAILED',
  'Username or password is incorrect': 'Username or password is incorrect',
}

export default header
