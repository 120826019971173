export const navmenu = {
  'Home': 'Home',
  'What we deliver': 'What we deliver',
  'Features': 'Features',
  'TAFS at work': 'TAFS at work',
  'News': 'News',
  'Contacts': 'Contacts',
}

export default navmenu
