import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Button } from 'reactstrap';
import li1 from '../../assets/img/icons/li1.svg';
import li2 from '../../assets/img/icons/li2.svg';
import li3 from '../../assets/img/icons/li3.svg';
import li4 from '../../assets/img/icons/li4.svg';
import li5 from '../../assets/img/icons/li5.svg';
import li6 from '../../assets/img/icons/li6.svg';
import li7 from '../../assets/img/icons/li7.svg';
import li8 from '../../assets/img/icons/li8.svg';
import li9 from '../../assets/img/icons/li9.svg';
import li10 from '../../assets/img/icons/li10.svg';

class Work extends Component {
  render() {
    return (
      <div>
        <div id='work-image'>
          <div className='container text-white text-center'>
            <h1 id='work-header'><Trans>TAFS AT WORK</Trans></h1>
          </div>
        </div>
        <div className='container my-5'>
          <p className='text-center'><Trans>TAFSAtWorkP</Trans></p>
          <div className='row my-5'>
            {
              examples.map((example, index) => {
                return (
                  <div key={index} className='col-lg-4 col-md-6'>
                    <div className='row my-3'>
                      <div className='col-2'>
                        <img src={example.icon} alt=''/>
                      </div>
                      <div className='col-10 pl-4'>
                        <h5 className='font-weight-bold'><Trans>{example.head}</Trans></h5>
                        <Trans>{example.text}</Trans>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div id='work-activity'>
          <div className='container text-white py-5'>
            <div id='work-activity-text'>
              <h3 className='text-center font-weight-bold mb-5'><Trans>UNUSUAL TRADING ACTIVITY</Trans></h3>
              <ul className='row'>
                {
                  activities.map((activity) => {
                    return (
                      <li key={activity} className='col-lg-6 mb-3'>
                        <Trans>{activity}</Trans>
                      </li>
                    )
                  })
                }
              </ul>
              <Button id='register-button' className='btn-danger autre-red-button mt-3 mx-4 py-2 px-5' href='/#register'><Trans>Register now</Trans></Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const examples = [
  {
    icon: li1,
    head: 'ex-h1',
    text: 'ex-p1'
  },
  {
    icon: li2,
    head: 'ex-h2',
    text: 'ex-p2'
  },
  {
    icon: li3,
    head: 'ex-h3',
    text: 'ex-p3'
  },
  {
    icon: li4,
    head: 'ex-h4',
    text: 'ex-p4'
  },
  {
    icon: li5,
    head: 'ex-h5',
    text: 'ex-p5'
  },
  {
    icon: li6,
    head: 'ex-h6',
    text: 'ex-p6'
  },
  {
    icon: li7,
    head: 'ex-h7',
    text: 'ex-p7'
  },
  {
    icon: li8,
    head: 'ex-h8',
    text: 'ex-p8'
  },
  {
    icon: li9,
    head: 'ex-h9',
    text: 'ex-p9'
  },
  {
    icon: li10,
    head: 'ex-h10',
    text: 'ex-p10'
  }
]

const activities = [
  'Unusual volume traded',
  'Unusual communications',
  'Unusual traded products',
  'Unusual counterparts',
  'Unusual clients',
  'Unusual jurisdictions'
]

export default Work
