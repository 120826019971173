export const deliver = {
  'WHAT WE DELIVER': '我们提供什么',
  'deliver-h1': '我们帮助前瞻性主动分析所有交易活动',
  'deliver-p1': '通过允许客户从无意交易中辨别并检测交易者/客户的有意欺诈，TAFS提供客户交易活动及其产生的影响在事件发生的确切时间的完全可见性',
  'deliver-h2': '我们提供更高效的合规流程',
  'deliver-p2': '使用数百种交易模式的自动识别，合规人员可以从耗时和手动的数据收集和异常检测方式转变为聚焦由算法自动识别的最紧急和最具威胁性问题',
  'deliver-h3': '我们帮助降低合规成本',
  'deliver-p3': 'TAFS允许合规官员：',
  'deliver-p3a': '•通过运行立即可用的应用程序，减少用于调查任何可疑活动所需的时间和人力资源',
  'deliver-p3b': '•减少对IT团队成员的数据和内部/监管报告的依赖',
  'deliver-h4': '预建行为模式检测算法',
  'deliver-h5': '对行业监管变化的适应性',
  'deliver-h6': '迭代数据处理模型允许有效的行为模式检测扩展',
  'deliver-h7': '自定义工具，临时算法创建',
  'deliver-h8': '涵盖交易者、客户、安全性和账户警报的综合手段',
  'deliver-h9': '完整的数据模型和算法逻辑文档',
  'deliver-h10': '完整文档，工作流程，以及审计跟踪',
  'deliver-h11': '广泛监控潜在的市场滥用/操纵',
  'deliver-h12': '我们降低负面交易事件的风险',
  'deliver-p12': '通过让所有交易者的活动可见并进行逻辑推理测试，TAFS既防止管理监督中隐藏的亏损头寸的长期累积，又防止了开放持续仅数秒欺诈性交易头寸的瞬时损失',
  'deliver-h13': '我们帮助及时回应监管机构的要求和询问',
  'deliver-p13': 'TAFS涵盖诸多资产类别、市场和司法管辖区域，使用经过实践测试的方案来确定关键模式。利用多个检测周期（例如，日内，每日，多日），我们的客户可以更迅速、更准确地回应监管机构的要求和询问',
  'deliver-h14': '我们帮助安排更好的文件化合规流程',
  'deliver-p14': '为合规官员提供市场和参考数据以便快速处理警报信息并有效管理案例调查。可以更快地实现符合法规要求的全面透明的审计跟踪',
}

export default deliver
