import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { translate } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './stylesheets/main.css';
import Header from './components/Header';
import NavMenu from './components/NavMenu';
import Home from './components/pages/Home';
import Deliver from './components/pages/Deliver';
import Features from './components/pages/Features';
import Work from './components/pages/Work';
import News from './components/pages/News';
import Contacts from './components/pages/Contacts';
import DevTeam from './components/pages/hidden/DevTeam';
import Footer from './components/Footer';

class App extends Component {
  state = {
    // language: this.props.i18n.language.includes('zh') ? 'zh' : 'en'
    language: 'en'
  }

  render() {
    return (
      <div>
        <Header languageSelected={this.state.language} changeLanguage={this.changeLanguage}/>
        <NavMenu />
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route path='/deliver' component={Deliver}/>
          <Route path='/features' component={Features}/>
          <Route path='/work' component={Work}/>
          <Route path='/news' component={News}/>
          <Route path='/contacts' component={Contacts}/>
          <Route exact path='/devteam' component={DevTeam}/>
          <Redirect to='/'/>
        </Switch>
        <Footer />
      </div>
    );
  }

  changeLanguage = language => ev => {
    this.props.i18n.changeLanguage(language);
    this.setState({language});
  }
}

export default translate("translations")(App);
