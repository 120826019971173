import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import logo from '../assets/img/brand/logo-dark.png';
import menu from './menu';

class NavMenu extends Component {
  state = {
    isOpen: false
  }

  render() {
    return (
      <div className='sticky'>
        <Navbar id='autre-navbar' className='container' expand='lg' light>
          <NavbarBrand href='http://www.autretech.com' id='autre-navbar-brand'><img src={logo} alt='autretech' /></NavbarBrand>
          <NavbarToggler id='autre-navbar-toggler' onClick={this.toggleNav} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar className='ml-auto'>
              {
                menu().map((item) => {
                  return (
                    <NavItem key={item.id} className='autre-nav-item'>
                      <NavLink to={item.ref} exact={true} activeClassName='active'><Trans>{item.name}</Trans></NavLink>
                    </NavItem>
                  )
                })
              }
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }

  toggleNav = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
}

export default NavMenu
