export const home = {
  'An international team of professionals in financial markets with R&D and management teams located in USA, Europe and Asia': '在美国、欧洲和亚洲拥有研发和管理团队的金融市场专业国际团队',
  'Register': '注册',
  'Learn More': '了解更多',
  'close': 'close',

  'Protection from': '防护',
  'ProtectionP': 'TAFS（交易反欺诈监控）帮助银行、资产管理者、金融机构和工业公司主动防范：',
  'FRAUD': '金融市场欺诈',
  'in financial markets': '',
  'ROGUE TRADING': '流氓交易',
  'hidden losses/ hidden positions/ hidden market & credit risks': '隐藏损失/隐藏位置/隐藏市场和信用风险',
  'MONEY-LAUNDERING': '金融市场的洗钱活动',
  'MARKET-ABUSE': '市场滥用',
  '(insider trading, market manipulation)': '(内幕交易，市场操纵)',

  'More1': 'TAFS帮助分析和提高特定交易者的效率，并增加交易操作的利润',
  'More2': '我们的交易反欺诈监控系统包括具有高级功能的全面模块化产品组合，因此能满足客户在一个屋檐下即可防范滥用和违规行为的需求。积木式设计让产品组合多样化。',

  'Registration': '注册',
  'Register now and you will have access to all the features of the TAFS service': '现在注册，即可访问TAFS服务的所有功能',

  'First name': '名字',
  'Last name': '姓氏',
  'E-mail': '电子邮件',
  'Phone number': '电话号码',
  'Company': '公司',
  'Position': '位置',
  'Comment': '评论 (可选)',
  'REGISTER NOW': '现在注册',
  'I have read and agree with ': '我已阅读并同意',
  'terms and conditions': '条款和条件',

  'Error': '错误',
  'Unexpected error': '出现意外错误',
  'Please provide a valid email address': '请提供一个有效的电子邮件地址',
  'Please provide your Company name': '请提供你的公司名称',
  'Please provide your position in a Company': '请提供您在公司的职位',
  'Please accept the terms and conditions': '请接受条款和条件',
  'Registration form': '报名表格',
  'confirm password': '确认密码',
  'Please enter username': '请输入用户名',
  'Please enter password': '请输入密码',
  'Password and confirm password do not match': '密码和确认密码不匹配',
  'This username is already taken': '该用户名已被注册',
  'Cancel': '关闭',

  'TERMS & CONDITIONS': '条款和条件',
  'TC-header': '条款和条件（“条款”）',
  'TC-subheader': '最近更新：2018年12月20日',
  'TC-p1': '在使用由Autretech（“我们”，“我们”或“我们的”）运营的tafs.us网站（“服务”）之前，请仔细阅读这些条款和条件（“条款”，“条款和条件”）。',
  'TC-p2': '您对本服务的访问和使用取决于您是否接受并遵守这些条款。这些条款适用于访问或使用本服务的所有访问者、用户和其他人。',
  'TC-p3': '访问或使用本服务即表示您同意遵守这些条款。如果您不同意这些条款的任何部分，那么您可能无法访问该服务。Autretech的条款和条件协议基于TermsFeed的条款和条件模板。',
  'Accounts': '帐号',
  'TC-p4': '当您在我们这里创建帐户时，您必须始终向我们提供准确、完整和最新的信息。否则即构成违反本条款的行为，这可能会导致我们的服务立即终止您的帐户。',
  'TC-p5': '您有责任保护用于访问服务的密码以及密码下的任何活动或操作，无论您的密码是用于我们的服务还是第三方服务。',
  'TC-p6': '您同意不向任何第三方披露您的密码。您必须在发现任何违反安全规定或未经授权使用您的帐户的行为后立即通知我们。',
  'Links To Other Web Sites': '链接到其他网站',
  'TC-p7': '我们的服务可能包含非Autretech拥有或控制的第三方网站或服务的链接。',
  'TC-p8': 'Autretech无法控制任何第三方网站或服务的内容、隐私政策或做法，也不承担任何责任。您进一步确认并同意，对于因使用或依赖任何此类内容、商品或服务或通过任何此类网站或服务导致或声称造成或与之相关的任何损害或损失，Autretech不承担任何直接或间接的责任或义务。',
  'TC-p9': '我们强烈建议您阅读您访问的任何第三方网站或服务的条款和条件以及隐私政策。',
  'Governing Law': '适用法律',
  'TC-p10': '这些条款应受美国纽约法律管辖和解释，不考虑其法律冲突规定。',
  'TC-p11': '我们未能执行这些条款的任何权利或规定将不被视为放弃这些权利。如果这些条款中的任何条款被法院认定为无效或不可执行，则这些条款的其余条款将继续有效。这些条款构成我们之间关于我们服务的完整协议，并取代和替换我们之间可能存在的有关服务的任何先前的协议。',
  'Changes': '变更',
  'TC-p12': '我们保留自行决定随时修改或替换这些条款的权利。如果修订是重要的，我们将尝试在任何新条款生效之前至少提前30天通知。变更是否属于重要变更将由我们自行决定。',
  'TC-p13': '在这些修订生效后继续访问或使用我们的服务，意味着您同意受修订条款的约束。如果您不同意新条款，请停止使用本服务。',
  'Contact Us': '联系我们',
  'TC-p14': '如果您对这些条款有任何疑问，请与我们联系。',
  'Close': '关闭',

  'Team': '团队',
  'show bio': '显示简历',
  'hide bio': '隐藏简历',

  'Gennady Belov': '根纳季·贝洛夫',
  'Director, R&D': '研发总监',
  'gb1': '贝洛夫先生是AutreTech的创始成员和研发部门负责人。在美国、欧洲和亚洲投资银行和基金的金融市场拥有逾10年的交易经验。 贝洛夫先生拥有超过5年的编程经验，其研发的先进软件系统能够检测金融市场系统中的欺诈交易。',
  'gb2': '贝洛夫先生于2014年创立了AutreTech开发团队。他负责AutreTech的产品设计和金融市场欺诈交易检测算法库的开发。',
  'gb3': '贝洛夫先生是MDC中国的创始成员，也是MDC 中国基金I的董事总经理，这是一家专注于中国、投资于消费品和零售行业的私募股权基金。 2009年，MDC中国基金I完成了对北京新合作超市连锁有限公司（NCSN）的4150万美元投资。 贝洛夫先生在成立MDC中国基金 I和投资NCSN方面发挥了关键作用。',
  'gb4': '在加入MDC之前，贝洛夫先生曾在AIG和欧洲Raiffeisenbank作为交易员从业多年。他在股票、固定收益工具和贵金属方面拥有广泛的投资组合管理和日内交易经验。',
  'gb5': '贝洛夫先生曾在英国牛津大学深造，并拥有莫斯科金融大学的经济学硕士学位。',

  'Nicholas Decker': '尼古拉斯·德克尔',
  'Director, Operations': '运营总监',
  'nd1': '德克尔先生在金融服务和技术工业拥有超过26年的创业经验。2018年，德克尔先生成为监管科技软件公司AutreTech的投资人和运营总监，现与AutreTech的管理团队合作，从事软件商业化并筹集额外资金。',
  'nd2': '德克尔先生的职业生涯是在德勤开始的，之后于纽约摩根大通任职，曾先后在摩根大通投资银行、零售银行和证券服务部门管理数百万美元的大型技术项目。',
  'nd3': '2009年，德克尔先生创立了MDC中国基金I，并于2010年成功完成了对北京新合作超市连锁公司（NCSN）的4150万美元投资，于2014年退出投资，为投资者带来优异回报。',
  'nd4': '德克尔先生还是德克尔资本的联合创始人。其主要职责是管理与投资者和客户的关系。他专注于德克尔资本私募股权交易的法律和财务领域的结构化和持续执行。德克尔先生拥有广泛的运营经验，并利用其经验和关系帮助投资组合公司实现其增长目标和加强其公司治理。',
  'nd5': '德克尔先生拥有纽约州立大学奥尔巴尼分校学士学位。',

  'Zheng Song': 'Zheng Song',
  'Director, Business Development': '业务发展总监',
  'zs1': 'Song女士拥有逾20年的投资银行和资产管理经验。 2018年，她成为监管科技软件公司AutreTech的投资人和业务开发总监，现与管理团队合作，从事软件商业化并筹集额外资金。',
  'zs2': 'Song女士的职业生涯是从在通用电气公司担任信息管理领导力计划成员开始的。她在公司融资并购集团任职期间拥有多年的投资银行业工作经验，在纽约的里昂证券和香港的瑞银华宝，她完成了一系列并购、首次公开募股、私募、股权和ADR交易。',
  'zs3': '2009年，她联合创办了MDC 中国基金I，完成了对北京新合作超市连锁有限公司（NCSN）的4150万美元投资。 2014年，MDC中国基金I退出了所有投资，为投资者带来优异回报。',
  'zs4': 'Song女士是德克尔资本的董事总经理，负责管理德克尔资本在美国的房地产资产。 2016年，Song女士带领团队成功完成了在纽约市的两次酒店收购。德克尔资本还积极投资技术和医疗保健业。',
  'zs5': 'Song女士拥有宾夕法尼亚大学沃顿商学院的MBA学位和印第安纳大学工商管理学院理学士学位，在伯明顿主校区她以优异的成绩毕业。Song女士精通中文和英文。',

  'Director, Integrations': '产品一体化总监',
  'ai1': 'Izhmyakov先生为AutreTech创始成员和副经理。Izhmyakov先生主管试点项目履行。',
  'ai2': '在美国和欧洲股票的市场拥有25多年的交易经验。',
  'ai3': 'Izhmyakov先生于2018年加盟AutreTech公司。他为市场结构专家。',
  'ai4': '现在他对AutreTech产品的一体化负责。',
  'ai5': '加盟AutreTech以前，他在Unicredit，Cargill和Raiffeisenbank 贸易部工作。',
  'ai6': 'Izhmyakov先生优异成绩毕业于俄罗斯联邦政府财政金融大学。',
}

export default home
