import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class Contacts extends Component {
  render() {
    return (
      <div>
        <div id='contacts-image'>
          <div className='container text-white text-center'>
            <h1 id='contacts-header'><Trans>CONTACTS</Trans></h1>
          </div>
        </div>
        <div className='container my-5'>
          <div className='w-50 mx-auto text-center text-autre-blue font-weight-bold'>
            <h1 className='my-3'><Trans>Get in touch</Trans></h1>
            <p className='my-5'><a className='text-autre-blue' href='mailto:info@autretech.com'>info@autretech.com</a></p>
            {/* <p className='my-2'><Trans>45 Rockefeller Plaza 1</Trans></p>
            <p className='my-2'><Trans>Suite 2508</Trans></p>
            <p className='my-2'><Trans>New York, New York 10111</Trans></p> */}
          </div>
        </div>
      </div>
    )
  }
}

export default Contacts
