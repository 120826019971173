import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import settings from '../../settings';

export default (OriginalComponent) => class WithLogin extends Component {
  state = {
    loggedIn: false,
    errorOpened: false
  }

  componentDidMount() {
    this.checkLogin();
    this.interval = setInterval(this.checkLogin.bind(this), 3000);
  }

  render() {
    return(
      <div>
        <OriginalComponent
          {...this.props}
          loggedIn = {this.state.loggedIn}
          login = {this.login}
          logout = {this.logout}
        />

        <Modal isOpen={this.state.errorOpened} toggle={this.toggleError} size={'sm'}>
          <ModalHeader toggle={this.toggleError}><Trans>SIGN IN FAILED</Trans></ModalHeader>
          <ModalBody>
            <p><Trans>Username or password is incorrect</Trans></p>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggleError}><Trans>Close</Trans></Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  checkLogin = () => {
    const body = {
      method: 'GET',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: 'include'
    };
    fetch(settings.websiteBack + '/user', body)
      .then(response => {
        switch (response.status) {
          case 200:
            this.setState({ loggedIn: true });
            break;
          case 401:
            this.setState({ loggedIn: false });
            break;
          default:
            console.log(response.status)
        }
      })
      .catch(err => console.error('Error: ', err));
  }

  login = (username, password) => {
    const body = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: 'include'
    };
    const query = settings.websiteBack + '/login?username=' + username + '&password=' + password;
    fetch(query, body)
      .then(response => {
        if (response.status === 200) {
          this.setState({ loggedIn: true })
        } else {
          this.toggleError()
        };
      })
      .catch(err => {
        console.error('Error: ', err);
        this.toggleError();
      });
  }

  logout = () => {
    const body = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: 'include'
    };
    fetch(settings.websiteBack + '/logout', body)
      .then()
      .catch(err => console.error('Error: ', err));
    this.setState({ loggedIn: false })
  }

  toggleError = () => {
    this.setState({
      errorOpened: !this.state.errorOpened
    });
  }
}
