import React, { Component } from 'react';
import { Trans, NamespacesConsumer } from 'react-i18next';
import { Alert, Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import settings from '../../../settings';

class RegistrationForm extends Component {
  state = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    position: '',
    comment: '',
    agree: false,
    termsOpened: false,
    errorOpened: false,
    errorMessage: 'Unexpected error',
    registerOpened: false,
    login: '',
    password: '',
    confirmation: '',
    showSuccess: false
  }

  render() {
    return (
      <div>
        <h1 className='text-center text-white py-2'><Trans>Registration</Trans></h1>
        <p className='text-center text-white py-2'><Trans>Register now and you will have access to all the features of the TAFS service</Trans></p>
        <NamespacesConsumer>
          { t =>
            <Form className='w-75 mx-auto'>
              <div className='row'>
                <div className='col-lg-6 my-2'>
                  <Input type='text' name='firstname' id='firstname' className='input-register' placeholder={t('First name')} onChange={this.handleInput('firstname')} />
                </div>
                <div className='col-lg-6 my-2'>
                  <Input type='text' name='lastname' id='lastname' className='input-register' placeholder={t('Last name')} onChange={this.handleInput('lastname')} />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 my-2'>
                  <Input type='email' name='email' id='email' className='input-register' placeholder={t('E-mail')} onChange={this.handleInput('email')} required />
                </div>
                <div className='col-lg-6 my-2'>
                  <Input type='text' name='phone' id='phone' className='input-register' placeholder={t('Phone number')} onChange={this.handleInput('phone')} />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 my-2'>
                  <Input type='text' name='company' id='company' className='input-register' placeholder={t('Company')} onChange={this.handleInput('company')} required />
                </div>
                <div className='col-lg-6 my-2'>
                  <Input type='text' name='position' id='position' className='input-register' placeholder={t('Position')} onChange={this.handleInput('position')} required />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 my-2'>
                  <Input type='textarea' name='comment' id='comment' className='input-register' placeholder={t('Comment')} onChange={this.handleInput('comment')} />
                </div>
              </div>
              <Button className='btn-danger autre-red-button my-2 py-3 w-100' onClick={this.validateForm}><Trans>REGISTER NOW</Trans></Button>
            </Form>
          }
        </NamespacesConsumer>
        <div className='py-3 text-center'>
          <Label>
            <Input type="checkbox" id="check-terms" onChange={this.toggleAgree}/>
            <span className='text-white opacity-7'><Trans>I have read and agree with </Trans></span><button id='terms-button' onClick={this.toggleTerms}><Trans>terms and conditions</Trans></button>
          </Label>
        </div>

        <Modal isOpen={this.state.termsOpened} toggle={this.toggleTerms} size={'lg'}>
          <ModalHeader toggle={this.toggleTerms}><Trans>TERMS & CONDITIONS</Trans></ModalHeader>
          <ModalBody>
            {terms}
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggleTerms}><Trans>Close</Trans></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.errorOpened} toggle={this.toggleError} size={'sm'}>
          <ModalHeader toggle={this.toggleError}><Trans>Error</Trans></ModalHeader>
          <ModalBody>
            <Trans>{this.state.errorMessage}</Trans>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggleError}><Trans>Close</Trans></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.registerOpened} toggle={this.toggleRegister} size={'sm'}>
          <ModalHeader toggle={this.toggleRegister}><Trans>Registration form</Trans></ModalHeader>
          <NamespacesConsumer>
            { t =>
              <ModalBody>
                <Input className='border-round mb-2' type='text' name='username' placeholder={t('username')} onChange={this.handleInput('username')} />
                <Input className='border-round mb-2' type='password' name='password' placeholder={t('password')} onChange={this.handleInput('password')} />
                <Input className='border-round' type='password' name='password' placeholder={t('confirm password')} onChange={this.handleInput('confirmation')} onKeyPress={e => e.key === 'Enter' ? this.signIn() : null}/>
              </ModalBody>
            }
          </NamespacesConsumer>
          <ModalFooter>
            <Button className='btn-danger autre-red-button' onClick={this.registrationAttempt}><Trans>Register</Trans></Button>
            <Button color='secondary' className='border-round' onClick={this.toggleRegister}><Trans>Cancel</Trans></Button>
          </ModalFooter>
        </Modal>

        <Alert className='success-alert' color='success' isOpen={this.state.showSuccess} toggle={this.hideSuccess}>
          <h4>Success</h4>
          <hr />
          <p>Registration request has been sent. We will contact you as soon as possible.</p>
        </Alert>
      </div>
    )
  }

  handleInput = type => e => {
    this.setState({[type]: e.target.value});
  }

  toggleAgree = (e) => {
    this.setState({agree: e.target.checked});
  }

  toggleTerms = () => {
    this.setState({termsOpened: !this.state.termsOpened});
  }

  toggleError = () => {
    this.setState({errorOpened: !this.state.errorOpened});
  }

  toggleRegister = () => {
    this.setState({registerOpened: !this.state.registerOpened});
  }

  showError = (message) => {
    this.setState({errorMessage: message});
    this.toggleError();
  }

  hideSuccess = () => {
    this.setState({showSuccess: false});
  }

  validateForm = () => {
    if (!/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(this.state.email)) {
      this.showError('Please provide a valid email address');
      return null
    }
    if (this.state.company === '') {
      this.showError('Please provide your Company name');
      return null
    }
    if (this.state.position === '') {
      this.showError('Please provide your position in a Company');
      return null
    }
    if (!this.state.agree) {
      this.showError('Please accept the terms and conditions');
      return null
    }
    this.toggleRegister();
  }

  registrationAttempt = () => {
    if (this.state.username === '') {
      this.showError('Please enter username');
      return null
    }
    if (this.state.password === '') {
      this.showError('Please enter password');
      return null
    }
    if (this.state.password !== this.state.confirmation) {
      this.showError('Password and confirm password do not match');
      return null
    }
    const query = 'firstname=' + this.state.firstname + '&lastname=' + this.state.lastname + '&email=' + this.state.email + '&phone=' + this.state.phone + '&company=' + this.state.company + '&position=' + this.state.position + '&comment=' + this.state.comment + '&username=' + this.state.username + '&password=' + this.state.password;
    const body = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: 'include'
    };
    fetch(settings.websiteBack + '/registration-idle?' + query, body)
      .then(response => {
        switch (response.status) {
          case 200:
            this.toggleRegister();
            this.setState({showSuccess: true});
            break;
          case 500:
            this.showError('This username is already taken');
            break;
          default:
            console.log(response.status);
        }
      })
      .catch(err => console.error('Error: ', err));
  }
}

const terms = (
  <div>
    <h4><Trans>TC-header</Trans></h4>
    <h6><i><Trans>TC-subheader</Trans></i></h6>
    <p><Trans>TC-p1</Trans></p>
    <p><Trans>TC-p2</Trans></p>
    <p><Trans>TC-p3</Trans></p>
    <h4><Trans>Accounts</Trans></h4>
    <p><Trans>TC-p4</Trans></p>
    <p><Trans>TC-p5</Trans></p>
    <p><Trans>TC-p6</Trans></p>
    <h4><Trans>Links To Other Web Sites</Trans></h4>
    <p><Trans>TC-p7</Trans></p>
    <p><Trans>TC-p8</Trans></p>
    <p><Trans>TC-p9</Trans></p>
    <h4><Trans>Governing Law</Trans></h4>
    <p><Trans>TC-p10</Trans></p>
    <p><Trans>TC-p11</Trans></p>
    <h4><Trans>Changes</Trans></h4>
    <p><Trans>TC-p12</Trans></p>
    <p><Trans>TC-p13</Trans></p>
    <h4><Trans>Contact Us</Trans></h4>
    <p><Trans>TC-p14</Trans></p>
  </div>
)

export default RegistrationForm
