import home from './home';
import deliver from './deliver';
import features from './features';
import work from './work';
import contacts from './contacts';

export const pages = {
  ...home,
  ...deliver,
  ...features,
  ...work,
  ...contacts
}

export default pages
