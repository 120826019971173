export const deliver = {
  'WHAT WE DELIVER': 'WHAT WE DELIVER',
  'deliver-h1': 'We help to Proactively Analyze All Trading Activities',
  'deliver-p1': 'By allowing a client to discern intentional from unintentional trading digressions and detect trader/customer fraud, TAFS provides full visibility of clients` trading activities at the exact time an event occurs and its resulting impact',
  'deliver-h2': 'We Deliver a More Efficient Compliance Process',
  'deliver-p2': 'Using the automated identification of hundreds of trading patterns, compliance personnel can shift from time-consuming and manual methods of data gathering and anomaly detection, to focusing on the most urgent and threatening issues automatically identified by algorithms',
  'deliver-h3': 'We Help to Reduce the Cost of Compliance',
  'deliver-p3': 'TAFS allows compliance officers:',
  'deliver-p3a': '• to reduce time and staff resources required to investigate any suspicious activity by implementing an application immediately ready for use',
  'deliver-p3b': '• to reduce reliance on IT team members for data and internal/regulatory reporting',
  'deliver-h4': 'Pre-built behavior pattern detection algorithms',
  'deliver-h5': 'Adaptability to industry regulatory changes',
  'deliver-h6': 'Iterative data processing model allows for efficient behavior pattern detection expansion',
  'deliver-h7': 'Tools for custom, ad hoc algorithm creation',
  'deliver-h8': 'Comprehensive instrument coverage with trader, customer, security and account alert focuses',
  'deliver-h9': 'Full documentation of data model and algorithm logic',
  'deliver-h10': 'Complete documentation, workflow, and audit trail',
  'deliver-h11': 'Extensive monitoring of potential market abuse/manipulation',
  'deliver-h12': 'We Decrease the Risk of Negative Trading Events',
  'deliver-p12': 'By making all traders` activities visible and logic reasoning tested, TAFS prevents both the long-lasting accumulation of loss-making positions hidden from the oversight of the management and the seconds-lasting opening of fraudulent trading positions implying instantaneous losses',
  'deliver-h13': 'We help to Duly Respond to Regulator demands and inquiries',
  'deliver-p13': 'TAFS covers numerous asset classes, marketplaces  and jurisdictions, through the use of practice-tested scenarios to identify key patterns. Leveraging multiple detection cycles (for example, intra-day, daily, multi-day), our clients can faster and with higher accuracy  respond to regulator demands and inquiries',
  'deliver-h14': 'We help to Arrange Better Documented Compliance Process',
  'deliver-p14': 'Provides compliance officers with market and reference data to enable the fast processing of alerts and efficient management of case  investigations. Allows to achieve quicker time to compliance with a comprehensive and transparent audit trail that meets regulatory needs',
}

export default deliver
