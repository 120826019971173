export const header = {
  'SignIn': '登入',
  'SignOut': '登出',
  'username': '用户名',
  'password': '密码',
  'SIGN IN FAILED': '登录失败',
  'Username or password is incorrect': '用户名或密码不正确',
}

export default header
