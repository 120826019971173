import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import server from '../../assets/img/deliver/server.jpg';

class Deliver extends Component {
  render() {
    return (
      <div>
        <div id='deliver-image'>
          <div className='container text-white text-center'>
            <h1 id='deliver-header'><Trans>WHAT WE DELIVER</Trans></h1>
          </div>
        </div>
        <div id='deliver-group' className='container text-autre-blue my-5'>
          <div className='row'>
            <div className='col-xl-4 col-md-6 my-3'>
              <div className='row'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h1</Trans></h5>
                  <p><Trans>deliver-p1</Trans></p>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6 my-3'>
              <div className='row'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h2</Trans></h5>
                  <p><Trans>deliver-p2</Trans></p>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6 my-3'>
              <div className='row'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h3</Trans></h5>
                  <p className='mb-0'><Trans>deliver-p3</Trans></p>
                  <p className='mb-0 pl-1'><Trans>deliver-p3b</Trans></p>
                  <p className='pl-1'><Trans>deliver-p3a</Trans></p>
                </div>
              </div>
            </div>

            <div className='col-xl-4 col-md-6'>
              <div className='row my-3'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h4</Trans></h5>
                </div>
              </div>
              <div className='row my-3'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h5</Trans></h5>
                </div>
              </div>
              <div className='row my-3'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h6</Trans></h5>
                </div>
              </div>
              <div className='row my-3'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h7</Trans></h5>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6 my-3'>
              <img src={server} alt='' width='100%'/>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='row my-3'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h8</Trans></h5>
                </div>
              </div>
              <div className='row my-3'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h9</Trans></h5>
                </div>
              </div>
              <div className='row my-3'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h10</Trans></h5>
                </div>
              </div>
              <div className='row my-3'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h11</Trans></h5>
                </div>
              </div>
            </div>

            <div className='col-xl-4 col-md-6 my-3'>
              <div className='row'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h12</Trans></h5>
                  <p><Trans>deliver-p12</Trans></p>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6 my-3'>
              <div className='row'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h13</Trans></h5>
                  <p><Trans>deliver-p13</Trans></p>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6 my-3'>
              <div className='row'>
                <div className='deliver-check col-2'></div>
                <div className='col-10'>
                  <h5><Trans>deliver-h14</Trans></h5>
                  <p><Trans>deliver-p14</Trans></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Deliver
