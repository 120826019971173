import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, NamespacesConsumer } from 'react-i18next';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Navbar, Nav, NavLink, NavItem } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import withLogin from './decorators/withLogin';
import flagen from '../assets/img/icons/flagen.svg';
import flagzh from '../assets/img/icons/flagzh.svg';

class Header extends Component {
  static propTypes = {
    languageSelected: PropTypes.string,
    changeLanguage: PropTypes.func.isRequired,
    // from withLogin
    loggedIn: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
  }

  state = {
    languageDropdown: false,
    loginModalOpened: false,
    username: '',
    password: ''
  }

  render() {
    return (
      <div id='autre-header'>
        <Navbar className='container py-1'>
          <Nav>
            <NavItem>
              <NavLink href='mailto:info@autretech.com' className='autre-nav-link'>
                <FontAwesome name='envelope' /><span className='d-none d-sm-inline'> info@autretech.com</span>
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className='float-right'>
            {/* <Dropdown nav isOpen={this.state.languageDropdown} toggle={this.toggleLanguageDropdown}>
              <DropdownToggle nav id='autre-nav-flag'>
                <img src={languages.get(this.props.languageSelected).flag} alt={languages.get(this.props.languageSelected).code}/>
                <FontAwesome name='caret-down' className='pl-2'/>
              </DropdownToggle>
              <DropdownMenu right>
                {
                  Array.from(languages).map((language) => {
                    if (this.props.languageSelected !== language[0]) {
                      return (
                        <DropdownItem key={language[0]} onClick={this.props.changeLanguage(language[0])}>
                          <img src={language[1].flag} alt={language[0]} /><span className='pl-3'>{language[1].name}</span>
                        </DropdownItem>
                      )
                    } else { return null }
                  })
                }
              </DropdownMenu>
            </Dropdown> */}
            {/*<NavItem>
              <NavLink href='#' className='autre-nav-link'>
                <FontAwesome name='headphones' /><span className='d-none d-sm-inline'> Support & FAQs</span>
              </NavLink>
            </NavItem>*/}
            <NavItem>
              <NavLink onClick={this.loginLogout} className='autre-nav-link cursor-pointer'>
                <FontAwesome name='user' /><span className='d-none d-sm-inline'> <Trans>{this.props.loggedIn ? 'SignOut' : 'SignIn'}</Trans></span>
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>

        <Modal isOpen={this.state.loginModalOpened} toggle={this.toggleLogin} size={'sm'}>
          <ModalHeader toggle={this.toggleLogin}><Trans>SignIn</Trans></ModalHeader>
          <NamespacesConsumer>
            { t =>
              <ModalBody>
                <Input className='border-round mb-2' type='text' name='username' placeholder={t('username')} onChange={this.handleInput('username')} />
                <Input className='border-round' type='password' name='password' placeholder={t('password')} onChange={this.handleInput('password')} onKeyPress={e => e.key === 'Enter' ? this.signIn() : null}/>
              </ModalBody>
            }
          </NamespacesConsumer>
          <ModalFooter>
            <Button className='btn-danger autre-red-button w-100 text-uppercase' onClick={this.signIn}><Trans>SignIn</Trans></Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  loginLogout = () => {
    if (this.props.loggedIn) {
      this.props.logout()
    } else {
      this.toggleLogin()
    }
  }

  toggleLanguageDropdown = () => {
    this.setState({ languageDropdown: !this.state.languageDropdown });
  }

  toggleLogin = () => {
    this.setState({
      loginModalOpened: !this.state.loginModalOpened
    });
  }

  signIn = () => {
    this.toggleLogin();
    this.props.login(this.state.username, this.state.password);
  }

  handleInput = type => e => {
    this.setState({[type]: e.target.value});
  }
}

const languages = new Map([
  ['en', {
    code: 'en',
    flag: flagen,
    name: 'English'
  }],
  ['zh', {
    code: 'zh',
    flag: flagzh,
    name: '中文'
  }],
])

export default withLogin(Header)
