import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import FontAwesome from 'react-fontawesome';
import video from '../../../assets/video/demo.mp4';

class VideoContainer extends Component {
  state = {
    showingVideo: false
  }

  render() {
    if (this.state.showingVideo) {
      return (
        <div className='fadeIn'>
          <p className='text-right cursor-pointer' onClick={this.hideVideo}><Trans>close</Trans> <FontAwesome name='times' /></p>
          <video width='100%' onEnded={this.hideVideo} controls>
            <source src={video} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>
      )
    } else {
      return (
        <FontAwesome id='home-play-icon' className='fadeIn cursor-pointer' name='play-circle' onClick={this.showVideo}/>
      )
    }
  }

  showVideo = () => {
    this.setState({ showingVideo: true })
  }

  hideVideo = () => {
    this.setState({ showingVideo: false })
  }
}

export default VideoContainer
