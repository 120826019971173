import React, { Component } from 'react';
import News1 from './News1';

class News extends Component {
  render() {
    return (
      <div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div id="news-image">
          <div className="container text-white text-center">
            <h1 id="news-header">NEWS</h1>
          </div>
        </div>
        <div style={{
          maxWidth: '1108px'
        }}>
          <News1 />
        </div>
      </div>
    );
  }
}

export default News;
