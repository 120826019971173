export default () => {
  return [
    {
      id: 0,
      ref: '/',
      name: 'Home'
    },
    {
      id: 1,
      ref: '/deliver/',
      name: 'What we deliver'
    },
    {
      id: 2,
      ref: '/features/',
      name: 'Features'
    },
    {
      id: 3,
      ref: '/work/',
      name: 'TAFS at work'
    },
    // {
    //   id: 4,
    //   ref: '/news/',
    //   name: 'News'
    // },
    {
      id: 5,
      ref: '/contacts/',
      name: 'Contacts'
    },
  ]
}
