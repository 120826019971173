import header from './header';
import pages from './pages/index';
import navmenu from './navmenu';
import footer from './footer';

export const dictionaryEn = {
  translations: {
    ...header,
    ...navmenu,
    ...pages,
    ...footer
  }
}

export default dictionaryEn
