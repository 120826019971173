export const navmenu = {
  'Home': '主页',
  'What we deliver': '我们提供什么',
  'Features': '特征',
  'TAFS at work': 'TAFS工作中',
  'News': '新闻',
  'Contacts': '联系方式',
}

export default navmenu
