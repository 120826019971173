export const home = {
  'An international team of professionals in financial markets with R&D and management teams located in USA, Europe and Asia': 'An international team of professionals in financial markets with R&D and management teams located in USA, Europe and Asia',
  'Register': 'Register',
  'Learn More': 'Learn More',
  'close': 'close',

  'Protection from': 'Protection from',
  'ProtectionP': 'TAFS (Trading Anti-Fraud Surveillance) helps banks, asset managers, financial institutions and industrial corporation to get proactive protection against:',
  'FRAUD': 'FRAUD',
  'in financial markets': 'in financial markets',
  'ROGUE TRADING': 'ROGUE TRADING',
  'hidden losses/ hidden positions/ hidden market & credit risks': 'hidden losses/ hidden positions/ hidden market & credit risks',
  'MONEY-LAUNDERING': 'MONEY-LAUNDERING',
  'MARKET-ABUSE': 'MARKET-ABUSE',
  '(insider trading, market manipulation)': '(insider trading, market manipulation)',

  'More1': 'TAFS helps to analyze and improve the effectiveness of particular traders and increase the profits from trading operations',
  'More2': 'Our Trading Anti-Fraud Surveillance system includes a comprehensive modular portfolio with high functionality, thereby meeting our customers‘ demands for protection against criminal abuse and non-compliance under one roof. The building-block design allows variable combination of products.',

  'Registration': 'Registration',
  'Register now and you will have access to all the features of the TAFS service': 'Register now and you will have access to all the features of the TAFS.pro service',

  'First name': 'First name',
  'Last name': 'Last name',
  'E-mail': 'E-mail',
  'Phone number': 'Phone number',
  'Company': 'Company',
  'Position': 'Position',
  'Comment': 'Comment (optional)',
  'REGISTER NOW': 'REGISTER NOW',
  'I have read and agree with ': 'I have read and agree with ',
  'terms and conditions': 'terms and conditions',

  'Error': 'Error',
  'Unexpected error': 'Unexpected error, please contact us via email',
  'Please provide a valid email address': 'Please provide a valid email address',
  'Please provide your Company name': 'Please provide your Company name',
  'Please provide your position in a Company': 'Please provide your position in a Company',
  'Please accept the terms and conditions': 'Please accept the terms and conditions',
  'Registration form': 'Registration form',
  'confirm password': 'confirm password',
  'Please enter username': 'Please enter username',
  'Please enter password': 'Please enter password',
  'Password and confirm password do not match': 'Password and confirm password do not match',
  'This username is already taken': 'This username is already taken',
  'Cancel': 'Cancel',

  'TERMS & CONDITIONS': 'TERMS & CONDITIONS',
  'TC-header': 'Terms and Conditions ("Terms")',
  'TC-subheader': 'Last updated: December 20, 2018',
  'TC-p1': 'Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the tafs.us website (the "Service") operated by Autretech ("us", "we", or "our").',
  'TC-p2': 'Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.',
  'TC-p3': 'By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service. The Terms and Conditions agreement for Autretech is based on the TermsFeed` Terms and Conditions Template.',
  'Accounts': 'Accounts',
  'TC-p4': 'When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.',
  'TC-p5': 'You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.',
  'TC-p6': 'You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.',
  'Links To Other Web Sites': 'Links To Other Web Sites',
  'TC-p7': 'Our Service may contain links to third-party web sites or services that are not owned or controlled by Autretech.',
  'TC-p8': 'Autretech has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Autretech shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.',
  'TC-p9': 'We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.',
  'Governing Law': 'Governing Law',
  'TC-p10': 'These Terms shall be governed and construed in accordance with the laws of Washington, United States, without regard to its conflict of law provisions.',
  'TC-p11': 'Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.',
  'Changes': 'Changes',
  'TC-p12': 'We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.',
  'TC-p13': 'By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.',
  'Contact Us': 'Contact Us',
  'TC-p14': 'If you have any questions about these Terms, please contact us.',
  'Close': 'Close',

  'Team': 'Team',
  'show bio': 'show bio',
  'hide bio': 'hide bio',

  'Gennady Belov': 'Gennady Belov',
  'Director, R&D': 'Director, R&D',
  'gb1': 'Mr. Belov is a founding member of AutreTech and the head of R&D department. He has over 10 years of trading experience in financial markets with US, European and Asian investment banks and funds. Mr. Belov has over 5 years of programming experience in developing advanced software systems that can detect fraudulent transactions in financial markets systems.',
  'gb2': 'Mr. Belov launched the AutreTech development team in 2014. He is responsible for AutreTech’s product design and development of the library of algorithms for detecting fraudulent transactions in financial markets.',
  'gb3': 'Mr. Belov was a founding member of MDC China and a Managing Director of MDC China Fund I, a China focused private equity fund investing in consumer products and retail industries. In 2009, MDC China Fund I completed a USD $41.5 million investment into Beijing New Cooperation Supermarket Chain Co. (NCSN).  Mr. Belov played a key role in both establishing MDC China Fund I and investing in NCSN.',
  'gb4': 'Prior to joining MDC, Mr. Belov worked for several years as a trader at AIG and Raiffeisenbank in Europe. He has extensive portfolio management and intra-day trading experience in stocks, fixed-income instruments and precious metals.',
  'gb5': 'Mr.Belov graduated with honours from Finance Academy, Moscow in International Economics and in 1997 completed his postgraduate studies at Oxford University in the United Kingdom.',

  'Nicholas Decker': 'Nicholas Decker',
  'Director, Operations': 'Director, Operations',
  'nd1': 'Mr. Decker has over 26 years entrepreneurial experience in the financial services and technology industries. In 2018 Mr. Decker became an investor and Director of Operations for AutreTech, a RegTech software company and is working with the management team of AutreTech to commercialize the software and raise additional capital.',
  'nd2': 'Mr. Decker started his career at Deloitte and then worked at JP Morgan Chase in New York managing larger multi-million dollar technology projects at JP Morgan investment banking, retail banking and securities services divisions.',
  'nd3': 'In 2009 Mr. Decker co founded MDC China Fund I and it successfully completed a USD $41.5 million investment into Beijing New Cooperation Supermarket Chain Company (NCSN) in 2010 and exited the investment in 2014 delivering a superior return to investors.',
  'nd4': 'Mr. Decker is also co-founder of Decker Capital. His primary responsibility is to manage relationships with investors and clients. He specializes in the structuring and on-going execution of the legal and financial aspects of Decker Capital’s private equity transactions. Mr. Decker has broad operations experience and leverages his experience and relationships to assist portfolio companies to achieve their growth objectives and enhance their corporate governance.',
  'nd5': 'Mr. Decker has a Bachelors Degree from the State University of New York at Albany.',

  'Zheng Song': 'Zheng Song',
  'Director, Business Development': 'Director, Business Development',
  'zs1': 'Ms. Song has over 20 years of investment banking and asset management experience. In 2018 she became an investor & Director of Business Development for AutreTech, a RegTech software company, and is working with the management team to commercialize the software and raise additional capital.',
  'zs2': 'Ms. Song started her career as a member of General Electric Company Information Management Leadership Program. She had years of investment banking experience during her tenure in the Corporate Finance and M&A Group at Credit Lyonnais Securities in New York and UBS Warburg in Hong Kong where she completed a number of M&A, Initial Public Offering, Private Placement, Equity and ADR transactions.',
  'zs3': 'In 2009, she co-founded MDC China Fund I which completed a USD $41.5 million investment into Beijing New Cooperation Supermarket Chain Co. (NCSN). In 2014, MDC China Fund I exited all of its investments delivering a superior return to investors.',
  'zs4': 'Ms. Song is a Managing Director at Decker Capital and is managing Decker Capital’s U.S. real estate assets. In 2016, Ms. Song led the team to successfully complete two hotel acquisitions in New York City.  Decker Capital is also actively investing in the technology and healthcare industries.',
  'zs5': 'Ms. Song has an MBA from the Wharton School, the University of Pennsylvania and a B.S. in Business Administration from Indiana University – Bloomington where she graduated with honors. Ms. Song is fluent in both Mandarin Chinese and English.',

  'Director, Integrations': 'Director, Integrations',
  'ai1': 'Mr. Izhmyakov is a founding member of AutreTech and is deputy CEO in charge of pilot and integration projects.',
  'ai2': 'He has over 25 years of trading experience in US and European bond and equity markets.',
  'ai3': 'Mr. Izhmyakov joined AutreTech team in 2014 as a expert in market microstructure and fraud detection.',
  'ai4': 'Currently he is responsible for AutreTech’s products integration into client and other vendor data processing and visualisation platforms.',
  'ai5': 'Prior to joining Autretech  Mr. Izhmyakov worked for several years as a trader at Unicredit, Cargill and Raiffeisenbank financial trading divisions.',
  'ai6': 'Mr.Izhmyakov graduated with honours from Finance Academy, Moscow in International Economics.',
}

export default home
