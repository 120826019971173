import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dictionaryEn from './en/index';
import dictionaryZh from './zh/index';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: dictionaryEn,
    zh: dictionaryZh,
  },
  fallbackLng: 'en',
  // lng: 'en', // uncomment to make english default
  // debug: true, // uncomment to log language changes into the console

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
});

export default i18n;
