import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import me from '../../../assets/img/home/team/autre-me.jpg';
import am from '../../../assets/img/home/team/autre-am.jpg';
import al from '../../../assets/img/home/team/autre-al.jpg';
import iv from '../../../assets/img/home/team/autre-iv.jpg';
import dk from '../../../assets/img/home/team/autre-dk.JPG';
import ak from '../../../assets/img/home/team/autre-ak.jpg';
import eg from '../../../assets/img/home/team/autre-eg.jpg';
import mb from '../../../assets/img/home/team/autre-mb.jpg';

class DevTeam extends Component {
  state = {
    showBio: -1
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      initialSlide: 0,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 544,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div id='team-carousel' className='w-75 mx-auto'>
        <h1 className='text-autre-blue text-center my-5'>Development team</h1>
        <Slider {...settings}>
          {
            team.map((person, index) => {
              return (
                <div key={person.name} className='text-center'>
                  <img src={person.photo} alt={person.name} className='carousel-photo mx-auto my-5'/>
                  <h5 className='font-weight-bold'>{person.name}</h5>
                  <h5 className='font-italic' style={{height: '50px'}}>{person.position}</h5>
                  <button className='btn btn-primary autre-blue-button' onClick={this.toggleBio(index)}>{(this.state.showBio === index) ? 'hide bio' : 'show bio'}</button>
                </div>
              )
            })
          }
        </Slider>

          {
            (this.state.showBio > -1) ? (
                <div className='w-50 mx-auto mt-5 text-autre-blue p-3 fadeIn'>
                  {
                    team[this.state.showBio].desc.map((paragraph) => {
                      return (
                        <p>{paragraph}</p>
                      )
                    })
                  }
                </div>
              ): null
          }

      </div>
    );
  }

  toggleBio = (index) => ev => {
    this.setState({ showBio: (this.state.showBio === index) ? -1 : index })
  }
}

const team = [
  {
    photo: me,
    desc: [
      'Mr. Eshtokin has over 5 years of experience as a software developer and a team lead in such companies as Sberbank Technologies, Moscow Exchange, R-Style SoftLab.',
      'Mr. Eshtokin has a Masters Degree in Automation of Production and Business Processes from Bauman Technical University.',
    ],
    name: 'Mikhail Eshtokin',
    position: 'Director, Software Development'
  },
  {
    photo: am,
    desc: [
      'Artem joined AutreTech in 2019 as a deputy director of IT department in charge of software design and front-end development.',
      'Prior to joining AutreTech he had worked for several years in CROC system integrating company as a lead front-end developer.',
      'Artem holds a Master’s degree in Robotics and Complex Automation from Bauman Technical University.'
    ],
    name: 'Artem Mozgovoy',
    position: 'Deputy Director, Software Development'
  },
  {
    photo: iv,
    desc: [
      'Ilya joined AutreTech team in 2018 and is responsible for UI development and IT infrastructure.',
      'Prior to joining AutreTech he had worked for six years in RETN Ltd. and X5 Retail Group as a network engineer.',
      'Mr. Vorobyev holds a Master’s degree in Engineering.'
    ],
    name: 'Ilya Vorobyev',
    position: 'DevOps Engineer'
  },
  {
    photo: al,
    desc: [
      'Before joining AutreTech in 2018, Alexander had worked for five years at BOEING international corporation, where he took part in developing hi-tech aircrafts.',
      'His main responsibility is JAVA/SCALA back-end development.',
      'Alexander holds a Master’s degree in Dynamics and Machine Durability from Bauman Technical University.'
    ],
    name: 'Alexander Li',
    position: 'TAFS-engine Software Development'
  },
  {
    photo: dk,
    desc: [
      'Daniil joined AutreTech team in 2019.',
      'His main responsibility covers AutreTech operating issues.',
      'Mr. Kozlov holds a Bachelor’s degree in Regional Studies (China) from the Academy of National Economy and Public Administration.'
    ],
    name: 'Daniil Kozlov',
    position: 'Operating Officer'
  },
  {
    photo: ak,
    desc: [
      'Before joining the team in 2019, Mr. Korovin worked for several years as a programmer in Computel, Raiffeisenbank and Masterdata.',
      'His main responsibility is JAVA/SCALA programming of TAFS-engine software.',
      'Mr. Korovin holds a degree in IT systems and Telecommunications from Bauman Technical University.'
    ],
    name: 'Alexander Korovin',
    position: 'TAFS-engine Software Development'
  },
  {
    photo: eg,
    desc: [
      'Mr. Gul joined AutreTech in 2019 as a programmer.',
      'His main responsibility is JAVA/SCALA back-end development.',
      'Mr. Gul graduated from Industrial College of Building Materials.'
    ],
    name: 'Evgeniy Gul',
    position: 'TAFS-engine Software Development'
  },
  {
    photo: mb,
    desc: [
      'Mikhail joined AutreTech team in 2019 as an R&D analyst and is developing new algorithms for TAFS-engine software. His main R&D tools are SQL, probability theory, mathematical statistics.',
      'Mr. Belov is a Mechanics and Mathematics student at Moscow State University.'
    ],
    name: 'Mikhail Belov',
    position: 'R&D analyst'
  },
]

export default DevTeam
