export const work = {
  'TAFS AT WORK': 'TAFS AT WORK',
  'TAFSAtWorkP': 'TAFS leverages a robust and comprehensive library of trading behaviour patterns to help companies monitor activities and detect actions that can lead to non-compliance with regulation and loss of reputation in the market. The areas of coverage include (and much more than these examples):',
  'ex-h1': 'MARKET MANIPULATION AND ABUSE',
  'ex-p1': 'Marking the Open/Close, Spoofing and Layering, Wash Trading and Parking, Spoofing and others',
  'ex-h2': 'INSIDER TRADING AND CONTROL ROOM',
  'ex-p2': 'Trading Ahead of Material Events, Front Running Related Products, Trading Against Restricted Lists',
  'ex-h3': 'OFF MARKET PRICING AND BEST EXECUTION',
  'ex-p3': 'Off Market Fair Pricing, Excessive Markups/Markdowns, Price Dis-improvement, Order Execution Un-timeliness',
  'ex-h4': 'ADVANCED FRAUD SCHEMES WITHIN MARKET PRICES',
  'ex-p4': 'Sophisticated schemes used for money-laundering or for personal enrichment by traders and clients at the expense of the employer/clients',
  'ex-h5': 'STRAIGHTFORWARD OFF-MARKET PRICING',
  'ex-p5': 'Straightforward off-market deals',
  'ex-h6': 'SOPHISTICATED OFF-MARKET PRICING',
  'ex-p6': 'Sophisticated off-market deals with no precise market prices for given historical dates (OTC options, SWAP, REPO, IRS, CIRS)',
  'ex-h7': 'DISGUISING OFF-MARKET DEALS AS MARKET ONES',
  'ex-p7': 'Price manipulation by individual traders in low liquidity markets aimed to make off-market deals  look like market ones',
  'ex-h8': 'POSITION PARKING',
  'ex-p8': 'End-quarterly/end-yearly balance sheet window dressing activity to hide losses. Detection of loss-making position parking to preserve traders’ bonuses. Profit-making position parking aimed to side-step position limits and enhance market exposure',
  'ex-h9': 'ILLEGAL TRANS-BORDER CAPITAL DEALS',
  'ex-p9': 'Free-of-delivery trans-border fund channeling via financial markets',
  'ex-h10': 'WASH TRADES',
  'ex-p10': 'Transactions aimed to transfer clients’ funds illegally into other banks or special purpose vehicles via closed-loop stock-exchange and OTC paired transactions',

  'UNUSUAL TRADING ACTIVITY': 'UNUSUAL TRADING ACTIVITY',
  'Unusual volume traded': 'Unusual volume traded',
  'Unusual communications': 'Unusual communications',
  'Unusual traded products': 'Unusual traded products',
  'Unusual counterparts': 'Unusual counterparts',
  'Unusual clients': 'Unusual clients',
  'Unusual jurisdictions': 'Unusual jurisdictions',
  'Register now': 'Register now',
}

export default work
